//const NEW_VERSION = "new-version";
export const ENDPOINT = {
  LIBRARY: `/library/get-library`,
  LIBRARY_CONTENT : `/library/get-library-data`,
  FILTERS: `/library/library-filter`,
  DELETE: `library/delete-library`,
  RENEWLICENSE: `library//renew-license`,
  LIBRARYSTATS: `/library/library-stats`,
  LIBRARYCHANGECONSENT: `/library/change-consent`,
  LIBRARYCREATE: `/library/create-library`,
  SPCCREATE: `/library/create-spc`,
  LIBRARYRESETSTATS: `/library/reset-stats`,
  LIBRARYREUPDATETAGS: `/library/update-tags`,
  LIBRARYREADDSUBLINK: `/library/add-subLink`,
  LIBRARYRESUBLINKLISTING: `/library/sublink`,
  LIBRARYGETPOPUP: `/library/get-popup`,
  LIBRARYDETAIL: `/library/user-detail`,
  LIBRARYSAVEPOPUP: `/library/save-pdf-popup`,
  LIBRARYGETARTICLE: `/library/get-pdffile`,
  LIBRARY_VIDEO_LISTING: `/library/video-listing`,
  LIBRARY_UPLOAD_VIDEO: `/library/upload-video`,
  SPC_PRO_LISTING: `/library/spc-productListing`,
  ADD_SPC_PRODUCT: "/library/add-product",
  LIBRARYGETSPC: `/library/get-spcListing`,
  LIBRARYGETSINGLESPC: `/library/getSpcById`,
  LIBRARYSPCDELETE: `/library/deletespc`,
  READER_LIST_DATA: `/reader/reader`,
  PROFILES_READER : `/reader/profile-reader`,
  MARKETING_READER_LIST_DATA: `/reader/marketing_reader`,
  SPC_PRO_DELETE: `/library/spcProductDelete/`,
  SPC_HELPER_LISTING: `/library/spc-helper-listing`,
  SPC_UPDATE: `/library/spc-edit`,
  LIBRARY_DETAIL_BY_ID: `/library/getLibraryDetail`,
  UPDATE_ARTICLE: `/library/update-article`,
  DELETE_PDF_FILE: `/library/delete-pdf-file`,
  UPDATE_PDF_FILE: `/library/update-pdf`,
  READER_CREATE: `/reader/create-reader`,
  ADD_PDF_WORD: `/library/add-pdf-word`,
  READER_USER_DROP: `/reader/user-detail`,
  MARKETING_READER_USER_DROP: `/reader/user-marketing-detail`,
  READER_GET_READER_USER: `/reader/get-reader`,
  UPLOAD_READER_FILE: `/reader/upload-readers`,
  USERTIMELINE: `/reader/get-timetracking`,
  MARKETINGUSERTIMELINE: `/reader/marketing-timetracking`,
  READERSTATUSUPDATE: `/reader/update-status`,
  MARKETINGREADERSTATUSUPDATE: `/reader/update-marketing-status`,
  READERSFILTER: `/reader/get-reader-filter`,
  MARKETINGREADERSFILTER: `/reader/get-marketing-reader-filter`,
  READERACTIVITY: `/reader/reader-activity`,
  MARKETINGREADERACTIVITY: `/reader/marketing-reader-activity`,
  TOPCLIENTS: `/analytics/reader-country`,
  TOPRESELLER: `/analytics/reader-country`,
  TOPSALES: `/analytics/reader-country`,
  ANALYTICS: "/analytics/graph",
  OPENING_BY_COUNTRY: "/analytics/reader-country",
  CONTENTANALYTICS: "/analytics/content-analytics",
  LIBRARY_SUBLINK_ANALYTICS: "/library/sublink-analtyics",
  READERANALYTICS: "/analytics/reader-analytics",
  SUBLINK_READER_ANALYTICS: "library/sublink-reader",
  OCTALATCH_TOTAL_HCP:
    "https://webinar.docintel.app/lmn/api/analytics/octo_lach_stats",
  OCTALATCH_DELIVERY_REGISTRATION:
    "https://webinar.docintel.app/lmn/api/analytics/octa_delivery_registration",

  DELIVERYTRENDS: "analytics/trend",
  //CAMPAINGSTAT: "analytics/campaign-stats",
  CAMPAINGSTAT: "analytics/delivery-campaign-stats",

  MAPLOCATION: `analytics/map-location`,
  SUBLINK_MAPLOCATION: `library/Sublink-country`,
  REPORTS: "analytics/reports",
  CONTENTTYPE: "analytics/content-type",
  CIS_CONTENT_TYPE: "analytics/contentTypeData",
  CONTENT_TYPE_GRAPH: "/analytics/content-type-graph",
  CONTENT: "analytics/content",
  TRENDING_TOPIC: "analytics/topic",
  READER_DOWNLOAD: `/reader/reader-file`,
  NEW_READER_DOWNLOAD: `/reader/new-readers-file`,
  MARKETING_READER_DOWNLOAD: `/reader/marketing_reader_file`,
  GETREADERTIMELINEDETAIL: `/reader/get-reader-detail`,
  COUNTRY_REGISTRATION: `analytics/country`,
  ADD_TAGS: `/library/add-tags`,
  INSERTBULKREADERS: `/reader/add-bulk-reader`,
  STATEBYREGION: `/analytics/octa-country`,
  DOWNLOADARTICLEREADERS: `/analytics/download-analytics`,
  DELETEREADER: `/reader/delete-reader`,
  LOGIN: `auth/login`,
  FILTERLIST: `https://webinar.docintel.app/lmn/api/distributes/filters_list`,
  READERLISTING: "reader/get-trail",
  REGISTERRD: `https://medarkive.com/Pages/RegisterRd`,
  EDITSITE: "reader/trailId",
  ADDSITE: "reader/add-trail",
  UPDATESITE: "reader/edit-trail",
  DELETESITE: "reader/delete-trail",
  UPDATE_TOPIC: "library/edit-product/",
  READER_ADD_FEATURES: "/reader/add-features",
  SPCFILTERS: "library/spc-filter",
  CHANGE_PASSWORD: "auth/change-password",
  INFORMED_USER_FORM: "https://webinar.docintel.app/lmn/contact_email",
  READER_REFRESH: "/reader/sync-reader",
  MARKETING_READER_REFRESH: "/reader/sync-marketing-reader",
  LIBRARY_CLONE_ARTICLE: "/library/clone-article",
  WEBINAR_QUESTION: `/auth/questions`,
  POLL_ANSWER: `/auth/poll-answer`,
  EVENT_ID: `/auth/event-data`,
  SESSION_LIST: `/auth/session-listing`,

  SITEREGISTER: "/site-registration",
  SITEREGISTERSORT: "/site-registration-sort",

  IRT_COUNT_GRAPH: "/v2/find-irt-count",
  WEBINAR_QUESTION_LISTING: "/auth/webinar-question",
  WEBINAR_All_QUESTION_LISTING: "/auth/webinar-all-question",
  WEBINAR_QUESTION_ONLY: "/auth/webinar-question-only",
  // RD_SITE_ENGAGEMENT: "/rd_site_engagement",
  RD_SITE_ENGAGEMENT:"https://webinar.docintel.app/lmn/api/analytics/rd_site_engagement",
  SITE_REGISTRATION_LIST: "/site-registration-list",
  INDIVIDUAL_TRAINING_COMPLETION: "/training-completion",
  INDIVIDUAL_TRAINING_COMPLETION_V2: "/v2/training-completion",
  TRAINING_COMPLETION_DROPDOWN: "/v2/training-completion-dropdown",
  TRAINING_COMPLETION_PAGE_CLICK: "/training-completion-page-click",
  MOST_POPULAR_CONTENT: "/most-popular-content",
  MOST_POPULAR_CONTENT_DROPDOWN: "/most-popular-content-dropdown",
  MOST_POPULAR_PAGE_CONTENT: "/most-popular-content_page_wise",
  MOST_POPULAR_SITE_CONTENT: "/most-popular-content_site_wise",
  QUESTION_ANSWER: "/auth/question-answer",
  QUESTION_UPDATE: "/auth/question-update",
  ADD_EVENT_DATA: "/auth/add-event-data",
  EVENT_SUBMIT: "/auth/event-submit",
  EVENT_CLOSE: "/auth/close-submit",
  ADD_WEBINAR_QUESTION: "/auth/add-webinar-question",
  ADD_MARKETING_FEATURES: "/reader/add-marketing-features",
  READER_MARKETING_USER_DROP: `/reader/user-marketing-detail`,
  GET_MARKETING_USER_DROP: `/reader/get-marketing-reader`,
  CREATE_MARKETING_READER: `/reader/create-marketing-reader`,
  UPDATETIMELINEMARKETINGDETAILS: `/reader/update-timeline-reader`,
  FEEDBACKLISTING: "https://api.docintel.app/api/v2/feedback-listing",
  COMMENTLISTING: "/analytics/rd-messages",
  FEEDBACKUPDATE: "https://api.docintel.app/api/v2/feedback-reply",
  FEEDBACKLISTINGMESSAGE: `/analytics/feedback-messages`,
  FEEDBACKIMPORT: `/analytics/download-feedback-excel`,
  QUESTIONUPDATE: `/analytics/update_question_reply`,
  ADD_QUESTION: "/webinar/add-question",
  EDIT_QUESTION: "/webinar/update-question",
  EVENT_LIST: "/webinar/get-event-list",
  WEBINAR_DETAIL: "/webinar/webinar-details",
  WEBINAR_ADD_NEW_EVENT: "/webinar/add-new-event",
  WEBINAR_GET_EVENT_LISTING: "/webinar/get-event-list",
  WEBINAR_UPDATE_EVENT: "/webinar/update-event",
  WEBINAR_DELETE_EVENT: "/webinar/delete-event",
  REGISTER: "/auth/register_module",
  FORGET: "/auth/forget-password",
  CREATE_WEBINAR_REGISTRATION: "/webinar/create_registration",
  GET_REGISTRATION_FORM: "/auth/getRegistrationFormByEventId",
  UPDATELOGS: "/reader/update-logs",
  USER_TRACKING: "/auth/pharma-marketing-tracking",
  GET_EMAIL_REMINDER: "http://192.168.0.162:8000/api/emailapi/change-email-reminder",
  WEBINAR_GET_EVENT_REGISTRATION: "/webinar/getEventRegistrations",
  WEBINAR_UPDATE_HCP_STATUS: "/webinar/update-hcp-status",
  WEBINAR_DELETE_USER: "/webinar/delete-user",
  WEBINAR_BLOCK_UNBLOCK_USER: "/webinar/block-unblock-user",
  // WEBINAR_EVENT_DM_LISTING:"webinar/event-dm-listing",
  WEBINAR_EVENT_DM_LISTING:"api/get-contact-us-data",
  WEBINAR_SETTINGS_UPDATE:"webinar/event-setting",
  WEBINAR_SETTINGS_GET:"webinar/get-event-setting",
  WEBINAR_EVENT_QUESTION_ANSWER:"webinar/event-question-answer",
  WEBINAR_CHANGE_QUESTION_STATUS:"webinar/change-question-status",
  WEBINAR_DELETE_QUESTION_ANSWER:"webinar/delete-question-answer",
  WEBINAR_SEND_ADMIN_QUESTION:"webinar/send-admin-question",
  WEBINAR_GET_EVENT_ATTENDEES:"webinar/get-event-attendees",
  WEBINAR_GET_EVENT_ATTENDEES_DETAILS:"webinar/get-attendees-more-details",
  STORECHATLINKDATA:"webinar/store-chat-link-data",
  // GETCHATLINKDATA:"webinar/get-chat-link-data",
  GETCHATLINKDATA:"auth/get-chat-link-data",
  CHANGEPOLLSORDER:"webinar/change-polls-order",
  RESETPOLL:"webinar/reset-polls",
  GETCOMMENTSBYQUESTIONID:"webinar/get-comments-by-question-id",
  WEBINAR_RESET_QUESTION_ANSWER:"webinar/reset-question-answer",
  LINKSANALYTICS: "/analytics/pdf-link-analytics",
  WEBINAR_GET_EVENT_ATTENDEES_GRAPH_DATA: "/webinar/get-online-readers",
  STORE_SURVEY_DATA:"auth/store-survey-data",
  GET_SURVEY_DATA:"auth/get-survey-data",
  GET_INFORMED_SURVEY_DATA:"auth/get-informed-survey-data",
  WEBINAR_EMAIL_COMPAIGN_LIST:"/webinarEmail/get-campaign-list",
  WEBINAR_EMAIL_GET_READERS_LIST:"/webinarEmail/get-readers-list",
  WEBINAR_EMAIL_GET_FILTER_LIST:"/webinarEmail/get-filter-list",
  WEBINAR_EMAIL_DELETE_COMPAIGN:"/webinarEmail/delete-list",
  WEBINAR_EMAIL_GET_TEMPLATE_LIST:"/webinarEmail/get-templates-list",
  WEBINAR_EMAIL_GET_AUTO_TEMPLATE_LIST:"/webinarEmail/get-auto-templates-list",
  INTERNAL_HCP:"/webinarEmail/internal-hcps",
  US_LIST:"/webinarEmail/us-list",
  REGISTERED_USERS:"/webinarEmail/registered-users",
  NO_REGISTERED_USERS:"/webinarEmail/no-registered-users",
  GET_DROPDOWN_DATA:"/webinarEmail/get-campaign-selection-data",
  GET_EMAIL_COUNT:"/webinarEmail/get-campaign-email-count",
  GET_TOTAL_EMAIL_REGISTRATION_COUNT:"/webinarEmail/total-registration-pie-chart-data",
  GET_TOTAL_EMAIL_REGISTRATION_USERS:"/webinarEmail/total-registration-pie-chart-dropdown-data",
  GET_EMAIL_DATA:"/webinarEmail/get-email-data",
  GET_OVERVIEW_DATA:"/webinarEmail/get-overview-data",
  GET_LIVESTREAM_DATA:"/webinarEmail/get-livestream-data",
  GET_ATTENDED_DATA:"/webinarEmail/get-attended-users-by-country",
  WEBINAR_POLL_ANALYTICS: "/webinarEmail/webinar-all-question",
  GET_REGION_STATS: "/webinarEmail/get-region-stats",
  ANALYTIC_EMAIL_LIST_DROPDOWN:"/webinarEmail/get-email-list-dropdown_data",
  ANALYTIC_ATTENDEES_DATA:"/webinarEmail/get-attendees-data",
  ANALYTIC_ATTENDEES_DROPDOWN_DATA:"/webinarEmail/get-attendees-dropdown-data",
  ANALYTIC_EVENT_LISTING:"/webinarEmail/get-attendees-dropdown-data",
  WEBINAR_SWITCH_USER:"/webinar/switch-user",
  WEBINAR_EVENT_STATS:"/webinar/download-stats",
  WEBINAR_EVENT_REGION_PIECHART_STATS:"/webinar/download-event-region-piechart-stats",
  RTRREFRESH_EBOOK:"/analytics/refresh_ebook_rtr",
  WEBINAR_EVENT_POLL_STATS:"/webinar/download-event-poll-stats",
  DOWNLOAD_EPS_FILE:"webinar/download-eps",
  GET_ALL_EVENT_LIST:"auth/get-all-event-list",
  ADD_COMMENT_TO_EVENT:"auth/add-comment-to-event",
  MANDATORY_READERS:"/reader/mandatory-readers",
  GET_RD_MANDATORY_READERS:"https://webinar.docintel.app/lmn/api/distributes/get_rd_mandatory_readers",
  EMAIL_COUNT_GRAPH: "/email-irt-count",
  IBU_USERS_LISTING:"/webinarEmail/ibu-users",
  OWN_USERS_LISTING:"/webinarEmail/own-internal-users",
  GET_BLOCKED_USERS:"/email/get-blocked-users",
  UNBLOCKED_USERS:"email/unblock-user",
  GET_CAMPAIGN_TEMPLATE: "email/get-campaign-template",
  LIBRARY_CONTENT : `/library/get-library-data`,
  IRT_COUNT_BY_CATEGORY:"/irt-count-by-category",
  RD_LANDING_TIMELINE:"reader/get-Rd-timeline",
  WEBINAR_POLL_STATS:"/webinar/download-poll-stats",
  GET_VIDEO_PLAYED_DETAIL:"/reader/get-video-played-detail",
  EMAIL_STATS_DOWNLOAD:"/email/download-campaign-stats",
  WEBINAR_EMAIL_STATS_DOWNLOAD:"/webinarEmail/download-webinar-email-stats"
};
