import consentCountries from "../Components/surveybuilder/SurveyComponents/Modals/consentCountries";
let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;

export const menuType = {
  multiple: {
    accordionType: "questionTypes",
    questionId: 0,
    type: "multiple",
    question: "",
    questionDescriptionEnabled: false,
    questionDescription: "",
    isOptional: false,
    visible: true,
    optionalLabel: "(Optional)",
    answer: [
      { value: "", answerId: 0 },
      { value: "", answerId: 0 },
    ],
    style: {},
    questionNo: 0,
    addOtherChoice: false,
    extra: {
      otherChoiceLabel: "Other",
      otherChoicePlaceholderText: "",
    },
  },
  checkbox: {
    accordionType: "questionTypes",
    type: "checkbox",
    questionId: 0,
    question: "",
    questionDescriptionEnabled: false,
    questionDescription: "",
    visible: true,
    isOptional: false,
    optionalLabel: "(Optional)",
    answer: [
      { value: "", answerId: 0 },
      { value: "", answerId: 0 },
    ],
    style: {},
    questionNo: 1,
    addOtherChoice: false,
    extra: {
      otherChoiceLabel: "Other",
      otherChoicePlaceholderText: "",
      addAllOfTheAbove: false,
      allOfTheAboveLabel: "All of the above",
    },
  },
  dropdown: {
    accordionType: "questionTypes",
    type: "dropdown",
    visible: true,
    questionId: 0,
    question: "",
    questionDescriptionEnabled: false,
    questionDescription: "",
    isOptional: false,
    optionalLabel: "(Optional)",
    addOtherChoice: false,
    answer: [
      // { value: "", answerId: 0 },
      // { value: "", answerId: 0 },
      { value: ["", ""], answerId: 0 },
    ],
    style: {},
    extra: {
      placeholder: "Please Select",
    },
  },
  freeText: {
    accordionType: "questionTypes",
    type: "freeText",
    questionId: 0,
    question: "",
    answer: [],
    placeHolderText: "",
    visible: true,
    questionDescriptionEnabled: false,
    questionDescription: "",
    isOptional: false,
    optionalLabel: "(Optional)",
    style: {},
    extra: {
      placeholder: "Type Your answer here",
      maxTextLength: 20,
    },
  },
  matrix: {
    accordionType: "questionTypes",
    type: "matrix",
    question: "",
    questionId: 0,
    visible: true,
    questionDescriptionEnabled: false,
    questionDescription: "",
    isOptional: false,
    optionalLabel: "(Optional)",
    answer: [
      {
        title: "Row 1",
        id: 0,
        answer: [
          {
            value: "Column 1",
            answerId: 0,
          },
          {
            value: "Column 2",
            answerId: 0,
          },
        ],
      },
    ],
    extra: {
      allowMultipleAnswer: false,
    },
    style: {},
  },
  rating: {
    accordionType: "questionTypes",
    type: "rating",
    visible: true,
    questionId: 0,
    question: "",
    questionDescriptionEnabled: false,
    questionDescription: "",
    isOptional: false,
    optionalLabel: "(Optional)",
    style: {},
    extra: {
      ratingScale: 5,
      ratingType: "stars",
      ratingColor: "#FAC755",
      leftSideLabel: "",
      rightSideLabel: "",
      maxRating: 5,
    },
    answer: [],
  },
  heading: {
    accordionType: "commonElements",
    type: "heading",
    questionId: 0,
    visible: true,
    question: "Headline Lorem ipsum dolor sit amet consectetur",
    questionDescriptionEnabled: false,
    questionDescription: "",
    style: {},
    answer: [],
  },

  paragraph: {
    accordionType: "commonElements",
    type: "paragraph",
    questionId: 0,
    visible: true,
    question: "Paragraph",
    style: {
      color: "",
    },
    answer: [],
  },

  divideLine: {
    accordionType: "commonElements",
    type: "divideLine",
    question: "DIVIDER",
    questionId: 0,
    visible: true,
    style: {
      lineStyle: "solid",
      lineWidth: 1,
      color: "",
      width: 100,
      height: 5,
    },
    answer: [],
  },
  image: {
    accordionType: "commonElements",
    type: "image",
    visible: true,
    questionId: 0,
    question: "",
    extra: {
      altText: "Image",
    },
    style: {
      width: "50",
      height: "auto",
    },
    answer: [],
  },
  consent: {
    accordionType: "commonElements",
    type: "consent",
    style: {},
    questionId: 0,
    visible: true,
    question: "",

    extra: {
      selectedLanguage: "",
      consentOptions: [
        {
          label: " ",
          isChecked: false,
        },
        {
          label: " ",
          isChecked: false,
        },
        {
          label: " ",
          isChecked: false,
        },
        {
          label: " ",
          isChecked: false,
        },
      ],
      consentDetails: [
        {
          nameLabel: " ",
          namePlaceholder: " ",
        },
        {
          emailLabel: " ",
          emailPlaceholder: " ",
        },
        {
          countryLabel: " ",
          countryPlaceholder: " ",
          countryOptions: [],
        },
      ],
      privacyLinks: [
        {
          octapharma: " ",
        },
        { docintel: " " },
        { termsOfUse: " " },
      ],
      cookiePolicy: " ",
      operatingStatement: " ",
    },
  },
};
