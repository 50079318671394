export const LibraryEditValidation = (data) => {
  const rdLikeArray=["56Ek4feL/1A8mZgIKQWEqg==","sNl1hra39QmFk9HwvXETJA==","MXl8m36VZFYXpgFVz3Pg0g=="]
  const isLikeRdAccount= rdLikeArray.includes(localStorage.getItem("user_id"))
  let error = {};

  if (!data?.limit?.toString()) {
    error.limit = "Limit is required";
  } else if (data?.limit) {
    if (data?.limit < 0) {
      error.limit = "Limit must be greater than or equal to 0";
    }
  }

  if (!data?.contentTitle) {
    error.contentTitle = "Content title is required!";
  }
  if (data?.hasOwnProperty("chapter") && data?.docintelFormat != 'video') {
    data?.chapter?.forEach((item, index) => {
      
      if(item.type == "video" && item.videoType == 'existing'){
        if(item?.selectedVideo == ''){
          if (!error?.chapter?.[index]) {
            error.chapter = { ...error.chapter, [index]: "Please select video" };
          }
        }
      }else{
      if (
        (item.hasOwnProperty("uploadFile") && item?.uploadFile == "") ||
        !item?.chapterTitle
      ) {
        if (!error?.chapter?.[index]) {
          if (!isLikeRdAccount) {
            error.chapter = {
              ...error.chapter,
              [index]: "Chapter is required",
            };
          } else {
            error.chapter = { ...error.chapter, [index]: "File is required" };
          }
          // error.chapter = {...error.chapter,[index]:"Chapter is required"};
        }
      }}
    });
  }
  if (data?.hasOwnProperty("pdfChapter")) {
    data?.pdfChapter?.forEach((item, index) => {
      if (  (item.hasOwnProperty("uploadFile") && item?.uploadFile == "")||!item?.chapterTitle) {
        if (!error?.pdfChapter?.[index]) {
          if (!isLikeRdAccount) {
            error.pdfChapter = {
              ...error.pdfChapter,
              [index]: "Chapter is required",
            };
          } else {
            error.pdfChapter = {
              ...error.chapter,
              [index]: "File is required",
            };
          }
        }
      }
    });
  }

  if (!data?.docintelFormat) {
    error.docintelFormat = "Docintel format is required!";
  } else if (data?.docintelFormat) {
    if (data?.docintelFormat == "pdf" && !data?.uploadFile 
      && !data?.pdf_file?.includes('.pdf')
    ) {
      error.uploadFile = "Please upload pdf file!";
    }
    if (
      data?.docintelFormat == "video" &&
      !data?.uploadFile 
      &&
      !data?.pdf_file?.includes('.mp4')
    ) {
      error.uploadVideo = "Please upload video file!";
    }
  }

  // if (!data?.docintelFormat) {
  //   error.docintelFormat = "Docintel format is required!";
  // } else if (data?.docintelFormat) {
  //   if (data?.docintelFormat == "pdf" && !data?.uploadFile) {
  //     error.uploadFile = "Please upload pdf file!";
  //   } else if (data?.docintelFormat == "video" && !data?.uploadFile) {
  //     error.uploadVideo = "Please upload video !";
  //   } else if (data?.docintelFormat == "ebook" && !fileCheck.length) {
  //     error.ebookErr = "Please upload pdf file !";
  //   } else if (data?.docintelFormat == "pdfspc" && !fileCheck.length) {
  //     error.ebookErr = "Please upload pdf file !";
  //   }
  // }

  // if (data.hasOwnProperty('trial')) {
  //   if(!data?.trial){
  //     error.trial  = "trial is required";
  //   }
  // }
  // if (data.hasOwnProperty('blindType')) {
  //   if(!data?.blindType){
  //     error.blindType  = "Blind Type is required";
  //   }
  // }

  return error;
};
